.swiper {
    height: 80vh;
}

.swiper-slide {

}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
}