.basicModalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 85vh;
    transform: translate(-50%, -50%);
    width: 85%;
    background: #ffff;
    border: 2px solid #000;
    padding: 20px;
}

.basicModalBox .ModalXCloseButton {
    height: 5%;
    display: flex;
    justify-content: end;

}

.basicModalBox .ModalComponentImage {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.basicModalBox .ModalComponentTitle {
    display: flex;
    justify-content: center;
    height: 6%
}

.basicModalBox .ModalComponentSpace {
    height: 82%;
    display: flex;
    overflow: scroll;
}

.basicModalBox .ModalComponentSmallerSpace {
    height: 58%;
    display: flex;
    overflow: scroll;
    padding-left: 30px;
    padding-right: 30px;
}

.basicModalBox .ModalComponentButtons {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .basicModalBox {
        width: 85%;
        height: 80vh;
    }

    .basicModalBox .ModalComponentSmallerSpace {
        height: 65%;
        padding-left: 15px;
        padding-right: 15px;
    }

}