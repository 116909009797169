.Navigation {
    display: flex;
    height: 10vh;
    justify-content: space-between;
}

.CenterMenu {

}

.RightSideMenu {

}
