.Footer {
    display: flex;
    flex-direction: column;
    background-color: #ebebe8;
}


.Footer .SiteLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 60px;

}

.Footer .SiteLinks .Header {
    display: flex;
    padding-bottom: 20px;
}

.Footer .PaymentMethods {
    display: flex;
    justify-content: center;
}

.Footer .SiteDescription {
    display: flex;
    justify-content: center;
}


.Footer .OurLogo {
    display: flex;
    justify-content: center;
}

.Footer .OurSocialMedia {
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin: auto;
}

.Footer .OurCountry {
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 600px) {
    .Footer .SiteLinks {
        flex-direction: column;
        padding: 40px 20px 20px 20px;

    }

    .Footer .SiteLinks .Header {
        padding-bottom: 5px;
    }

    .Footer .SiteLinks .Header .ButtonGroup {
        width: 100%;
        display: flex;
        justify-content: space-between;

    }

    .Footer .SiteLinks .Header .ButtonGroup:first-child {
        display: flex;
    }

}